<template>
    <div class="w-full h-full flex flex-col">
        
        <div 
            v-if="offers && offers.length > 0"
            class="
                flex-none 
                flex 
                items-center
                flex-col
                
                md:h-32
                md:py-8
                md:px-16
                md:flex-row
            ">
            
            <div 
                class="
                    flex items-center justify-center
                    h-12 
                    w-full
                    bg-clay
                    md:bg-transparent
                    
                    md:h-auto
                    md:w-auto
                ">
                
                <h1 class="text-white md:text-black">
                    {{$t('offers.offers')}}
                </h1>
            </div>
            
            <div class="w-full flex items-center px-4 md:px-4 h-20">
                <div class="flex-grow flex items-center">
                    <div 
                        class="
                            relative mr-4 
                            
                            w-full
                            md:w-64
                        ">
                        
                        <input 
                            class="w-full flex-none rounded-full px-8 py-3 outline-none "
                            :placeholder="$t('offers.searchOffers')"
                            v-model="searchString"
                        />
                        
                        <i 
                            v-if="!searchString" 
                            class="fas fa-search text-lg text-gray-400 absolute right-0 top-0 mt-4 mr-4 pointer-events-none" 
                        />
                        <i 
                            v-else
                            class="fas fa-times text-lg text-gray-400 absolute right-0 top-0 mt-4 mr-5 cursor-pointer" 
                            @click="searchString = ''"
                        />
                    </div>
                    <div class="relative mr-4">
                        <div 
                            @click="showSearchFilter = true"
                            class="bg-white cursor-pointer w-full flex-none rounded-full px-4 py-3 flex items-center">
                            
                            <i 
                                class="fas fa-tasks text-lg my-1 text-gray-400 hover:text-black "
                                title="Filter results"
                            />
                            
                            <div 
                                v-if="searchFilter.length != statuses.length"
                                class="ml-4 flex">
                                
                                <div 
                                    v-for="status in statuses"
                                    :key="status.code"
                                    class="px-2 py-1 rounded capitalize ml-2"
                                    :class="status.class"
                                    v-show="searchFilter.find(s => s == status.code )">
                                    
                                    {{$t('offers.statuses.'+status.code)}}
                                </div>
                            </div>
                            
                            <i 
                                v-if="searchFilter.length != statuses.length"
                                class="fas fa-times text-lg text-gray-400 ml-3 hover:text-black " 
                                @click.stop="searchFilter = statuses.map( s => s.code)"
                            />
                            
                        </div>
                    </div>
                    <h2 
                        v-if="searchString"
                        class="opacity-50 ml-4">
                        
                        {{offersFiltered.length }} {{ $t('general.hitsOnSearch') }}
                    </h2>
                </div>
                <div class="flex-none flex">
                    <div 
                        class="fas fa-redo-alt block flex items-center justify-center bg-white text-gray-400 w-10 h-10 mr-4 rounded-full cursor-pointer refresh"
                        :class="{'loading' : loading == 'refresh'}"
                        @click="refresh()"
                    />
                    
                    <router-link 
                        :to="{ name: 'offers-create' }"
                        class="block flex items-center justify-center bg-cyan text-white w-10 h-10  rounded-full">
                        
                        <i class="fas fa-plus text-lg" />
                    </router-link>
                </div>
            </div>
        </div>
        
        <div 
            class="
                w-full h-full flex-grow overflow-y-scroll 
                p-4
                pt-0
                md:px-16 
                md:pb-16
            ">
            
            <div 
                v-if="!offers"
                class="fixed inset-0 flex items-center justify-center">
                
                <div class="spinner"></div>
            </div>
            
            <transition-group 
                appear
                name="slide-up" 
                tag="div"
                class="
                    grid 
                    grid-cols-1 
                    lg:grid-cols-2 
                    xl:grid-cols-3 
                    gap-4
                    md:gap-16
                "
                v-else-if="offers.length > 0">
                
                <!-- <pre 
                    v-for="(offer) in offersFiltered"
                    :key="offer.offerId"
                    v-html="offer"
                /> -->
                
                <OfferCard 
                    v-for="(offer,i) in offersFiltered"
                    :key="'offer_'+offer.offerId+'_'+i"
                    :offer="offer"
                    @view="offerEdit = offer"
                    class="slide-up-item"
                />
            </transition-group>
            
            <transition 
                v-else
                name="pop"
                appear>
                
                <div class="h-full w-full flex items-center justify-center pointer-events-none">
                    <div class="bg-white rounded-full p-12 text-center w-100 h-100">
                        <img 
                            src="/assets/illustrations/undraw_browsing_online_sr8c.svg" 
                            class="object-contain mb-8 w-48 h-48 mx-auto"
                            alt="No records illustrations"
                        />
                        <router-link 
                            :to="{ name: 'offers-create' }"
                            class="button submit mx-auto pointer-events-auto">
                            
                            {{$t('offers.createOfferNow')}}
                        </router-link>
                    </div>
                </div>
            </transition>
        </div>
        
        <div 
            v-if="offerEdit"
            class="
                absolute inset-0 flex
                mb-16 
                md:mb-0
            ">
            
            <div 
                @click="offerEdit = null"
                class="flex-grow bg-black opacity-25 ">
                
            </div>
            
            <transition 
                v-if="offerEdit && offerEdit.offerId"
                name="fade">
                
                <OfferEdit 
                    :offerId="offerEdit.offerId" 
                    @close="offerEdit = null"
                    class="
                        absolute bg-white right-0 overflow-hidden w-full
                        md:rounded-l-lg 
                        md:w-150
                    "
                />
            </transition>
        </div>
        
        <Modal 
            v-if="showSearchFilter"
            @close="showSearchFilter = false">
            
            <header slot="header">
                <div class="py-4 px-6">
                    {{$t('offers.filterOffers')}}
                </div>
            </header>
            
            <div class="pb-6 px-6">
                <label 
                    v-for="status in statuses"
                    :key="status.code"
                    class="my-2"
                    @click.stop.prevent="updateSearchFilter(status)">
                    
                    <input 
                        type="checkbox" 
                        class="mr-2"
                        :checked="searchFilter.find(s => s == status.code)"
                    />
                    
                    {{$t('offers.statuses.'+status.code)}}
                </label>
            </div>
        </Modal>
        
    </div>
</template>

<script>
    export default {
        components: {
            'OfferCard': () => import('@/components/offers/OfferCard.vue'),
            'OfferEdit': () => import('@/components/offers/OfferEdit.vue'),
        },
        
        computed:{
            offers(){
                return this.$store.getters.getOffers;
            },
            
            products(){
                return this.$store.getters.getProducts;
            },
            
            offersFiltered(){
                let offers;
                
                if (!this.searchString) {
                    offers = this.offers;
                }
                else {
                    offers = this.offers.filter( o => {
                        // flatten all values into a CSV string
                        
                        let itemValues = [
                            o.offerId,
                            o.heading,
                            o.text,
                            o.priceTotal,
                        ];
                        
                        o.products.forEach( pO => {
                            const product = this.products.find( pP => pP.productId == pO.productId );
                            
                            if (product) {
                                itemValues.push(product.productId);
                                itemValues.push(product.name);
                                itemValues.push(product.price);
                            }
                        });
                        
                        if (o.contact) {
                            itemValues.push(o.contact.name);
                            itemValues.push(o.contact.email);
                            itemValues.push(o.contact.phone);
                        }
                        
                        const matchString = itemValues.join(';').toLowerCase();
                        
                        if ( matchString.includes( this.searchString.toLowerCase().trim() )) {
                            return o;
                        }
                    });
                }
                
                return offers.filter( o => {
                    return this.searchFilter.find( s => s == o.status)
                });
                
            }
        },
        
        data(){
            return {
                loading: null,
                offerEdit: null,
                searchString: '',
                searchFilter: [],
                showSearchFilter: false,
                // refreshing: false,
                statuses: [
                    {
                        code: 'draft',
                        class: 'bg-yellow-500 text-white',
                    },
                    {
                        code: 'active',
                        class: 'bg-cyan text-white',
                    },
                    {
                        code: 'accepted',
                        class: 'bg-green-500 text-white',
                    },
                    {
                        code: 'declined',
                        class: 'bg-red-500 text-white',
                    },
                    {
                        code: 'deleted',
                        class: 'bg-gray-500 text-white',
                    },
                ]
            }
        },
        
        methods: {
            async refresh(){
                try {
                    this.loading = 'refresh';
                    
                    const refreshPromises = [
                        this.$store.dispatch('listOffers'),
                        this.$store.dispatch('listMessages', {method: 'listActiveOffers'}),
                    ]
                    
                    await Promise.allSettled(refreshPromises);
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        title: 'Error', 
                        text: this.$t('offers.problemRefreshing')
                    });
                    console.error(e);
                } 
                finally {
                    this.loading = null;
                }
            },
            
            updateSearchFilter( status ){
                if ( this.searchFilter.find(s => s == status.code) ) {
                    this.searchFilter = this.searchFilter.filter(s => s != status.code);
                }
                else {
                    this.searchFilter.push(status.code);
                }
            }
        },
        
        mounted(){
            this.refresh();
            this.searchFilter = this.statuses.map( s => s.code);
            
            if (this.$route.params.offerId) {
                this.offerEdit = this.$store.getters.getOffers.find(offer => offer.offerId == this.$route.params.offerId);
            }
            
        }
    }
</script>

<style lang="scss" >
</style>
